<template>
  <div
    class="cursor-pointer text-slate-900 dark:text-white text-2xl"
    @click="toggleMsidebar"
  >
    <Icon icon="heroicons-outline:menu-alt-3" />
    
  </div>
  <div
          type="button"
          class="cursor-pointer text-slate-900 dark:text-white text-2xl"
          @click="link()"
        >
          <div class="flex-none text-lg">
            <Icon icon="heroicons-outline:login" />
          </div>
        
        </div>
</template>
<script setup>
import Icon from "@/components/Icon";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

// dispath toggleMsidebar
const toggleMsidebar = () => {
  store.dispatch("toggleMsidebar");
};
const link = () => {
      router.push("/");
      localStorage.removeItem("activeUser");
    }
</script>
<style lang="scss"></style>
