<template>
  <div
    class="bg-white bg-no-repeat custom-dropshadow footer-bg dark:bg-slate-700 flex justify-around items-center backdrop-filter backdrop-blur-[40px] fixed left-0 w-full z-[9999] bottom-0 py-[12px] px-4"
  >
    <!-- Chat Link -->
    <router-link :to="chatRoute" v-slot="{ isActive }">
      <span
        class="relative cursor-pointer rounded-full text-[20px] flex flex-col items-center justify-center"
        :class="[
          isActive ? 'text-primary-500' : 'dark:text-white text-slate-900',
        ]"
      >
        <Icon icon="heroicons-outline:mail" />
        <!-- Dynamic unread chat messages count -->
        <span
          v-if="unreadMessagesCount > 0"
          class="absolute right-[5px] lg:top-0 -top-2 h-4 w-4 bg-red-500 text-[8px] font-semibold flex flex-col items-center justify-center rounded-full text-white z-[99]"
        >
          {{ unreadMessagesCount }}
        </span>
      </span>
      <span
        class="block text-[11px]"
        :class="[
          isActive ? 'text-primary-500' : 'text-slate-600 dark:text-slate-300',
        ]"
      >
        Messages
      </span>
    </router-link>

    <!-- Profile Link -->
    <router-link
      v-slot="{ isActive }"
      :to="userProfileLink"
      class="relative bg-white bg-no-repeat backdrop-filter backdrop-blur-[40px] rounded-full footer-bg dark:bg-slate-700 h-[65px] w-[65px] z-[-1] -mt-[40px] flex justify-center items-center"
    >
      <div
        class="h-[50px] w-[50px] rounded-full relative left-[0px] top-[0px] custom-dropshadow"
      >
        <img
          :src="userAvatar"
          alt="User Avatar"
          class="w-full h-full rounded-full"
          :class="[
            isActive
              ? 'border-2 border-primary-500'
              : 'border-2 border-slate-100',
          ]"
        />
      </div>
    </router-link>

    <!-- Notifications Link -->
    <router-link :to="{ name: 'notifications' }" v-slot="{ isActive }">
      <span
        class="relative cursor-pointer rounded-full text-[20px] flex flex-col items-center justify-center"
        :class="[
          isActive ? 'text-primary-500' : 'dark:text-white text-slate-900',
        ]"
      >
        <Icon icon="heroicons-outline:bell" />
        <!-- Dynamic unread notifications count -->
        <span
          v-if="unreadNotificationsCount > 0"
          class="absolute right-[17px] lg:top-0 -top-2 h-4 w-4 bg-red-500 text-[8px] font-semibold flex flex-col items-center justify-center rounded-full text-white z-[99]"
        >
          {{ unreadNotificationsCount }}
        </span>
      </span>
      <span
        class="block text-[11px]"
        :class="[
          isActive ? 'text-primary-500' : 'text-slate-600 dark:text-slate-300',
        ]"
      >
        Notifications
      </span>
    </router-link>
  </div>
</template>
<script>
import Icon from "@/components/Icon";
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    Icon,
  },
  setup() {
    const store = useStore();

    // Get active user details
    const activeUser = JSON.parse(localStorage.getItem("activeUser")) || {};
    const activeUserId = ref(activeUser.id || null);
    const userRoleId = ref(activeUser.role_id);

    // Dynamic avatar
    const userAvatar = ref(
      activeUser.avatar
        ? `https://apidash.smartpress.kz/storage/${activeUser.avatar}`
        : require("@/assets/images/all-img/user.png") // Default avatar
    );

    // Dynamic profile route
    const userProfileLink = computed(() => {
      return userRoleId.value === 9
        ? { name: "author-profile", params: { id: activeUserId.value } }
        : { name: "profile", params: { id: activeUserId.value } };
    });

    // Dynamic chat route
    const chatRoute = computed(() => {
      switch (userRoleId.value) {
        case 3:
          return { name: "initiatorChat" };
        case 4:
          return { name: "Project-manager chat" };
        case 5:
          return { name: "Executor chat" };
        case 6:
          return { name: "accountingChat" };
        case 9:
          return { name: "Author chat" };
        case 12:
          return { name: "stockChat" };
        case 11:
          return { name: "typographyChat" };
        default:
          return { name: "chat" };
      }
    });

    // Notifications and messages counters
    const unreadNotificationsCount = ref(0);
    const unreadMessagesCount = ref(0);

    // Fetch unread notifications
    const fetchNotifications = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          "https://apidash.smartpress.kz/api/notifications",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        unreadNotificationsCount.value = data.filter(
          (notification) => notification.read_at === null
        ).length;
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      }
    };

    // Fetch unread messages
    const fetchChatMessages = async () => {
      try {
        await store.dispatch("fetchContacts");
        const contacts = store.getters.getContacts || [];
        unreadMessagesCount.value = contacts.reduce(
          (sum, contact) => sum + (contact.unredmessage || 0),
          0
        );
      } catch (error) {
        console.error("Failed to fetch chat messages:", error);
      }
    };

    // On mounted, fetch counters
    onMounted(() => {
      fetchNotifications();
      fetchChatMessages();
    });

    return {
      chatRoute,
      userProfileLink,
      userAvatar,
      unreadNotificationsCount,
      unreadMessagesCount,
    };
  },
};
</script>
