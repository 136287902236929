import axios from 'axios';

const state = {
  requests: [],
  addModal: false, // Tracks if the add modal is open
  editModal: false,
  currentEditingRequest: null,
  viewModal: false,
  currentViewingRequest: null,
};

const getters = {
  requests: (state) => state.requests,
  addModal: (state) => state.addModal,
  editModal: state => state.editModal,
  currentEditingRequest: state => state.currentEditingRequest,
  viewModal: state => state.viewModal,
  currentViewingRequest: state => state.currentViewingRequest,
};

const actions = {
  async fetchRequests({ commit }) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://apidash.smartpress.kz/api/support', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      commit('SET_REQUESTS', response.data);
    } catch (error) {
      console.error('Error fetching support requests:', error);
    }
  },
  async createRequest({ dispatch }, formData) {
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://apidash.smartpress.kz/api/support', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch('fetchRequests'); // Refresh requests after creating a new one
    } catch (error) {
      console.error('Error creating support request:', error);
      throw error;
    }
  },
  openEditModal({ commit }, requestData) {
    commit('SET_CURRENT_EDITING_REQUEST', requestData);
    commit('SET_EDIT_MODAL', true);
  },
  closeEditModal({ commit }) {
    commit('SET_CURRENT_EDITING_REQUEST', null);
    commit('SET_EDIT_MODAL', false);
  },
  async updateRequest({ dispatch }, { id, data }) {
    const token = localStorage.getItem('token');
    await axios.post(`https://apidash.smartpress.kz/api/support-request/${id}`, data, {
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    });
    // Optionally refetch after update
    await dispatch('fetchRequests');
  },
  openSupportRequestModal({ commit }) {
    commit('SET_ADD_MODAL', true);
  },
  closeSupportRequestModal({ commit }) {
    commit('SET_ADD_MODAL', false);
  },
  openViewModal({ commit }, requestData) {
    commit('SET_CURRENT_VIEWING_REQUEST', requestData);
    commit('SET_VIEW_MODAL', true);
  },
  closeViewModal({ commit }) {
    commit('SET_CURRENT_VIEWING_REQUEST', null);
    commit('SET_VIEW_MODAL', false);
  },
};

const mutations = {
  SET_REQUESTS(state, requests) {
    state.requests = requests;
  },
  SET_ADD_MODAL(state, status) {
    state.addModal = status;
  },
  SET_EDIT_MODAL(state, payload) {
    state.editModal = payload;
  },
  SET_CURRENT_EDITING_REQUEST(state, request) {
    state.currentEditingRequest = request;
  },
  SET_VIEW_MODAL(state, payload) {
    state.viewModal = payload;
  },
  SET_CURRENT_VIEWING_REQUEST(state, request) {
    state.currentViewingRequest = request;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
